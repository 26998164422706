import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnProduct(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>Understand sf2Pro by Business Scenarios</title>
        <meta
          name="description"
          content="Typical sf2Pro scenarios include: Setup Team, Define Business Rules, Start Delivery, Monitor Status, Review and Analysis and Studio Management"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,Yige"
        />
      </Helmet>
      <div className="pnDescMainLeft">
        <h3 className="secTitlePnLeft">sf2Pro Scenario Illustration</h3>
        <p className="secDescriptionPnLeft">Understand sf2Pro by Business Scenarios</p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div className="pnWrapperRowInside">
          <div style={{ width: "45%" }}>
            <h3 className="secTitlePnLeft">Scenario 1: Setup Teams</h3>
            <p className="secDescriptionPnLeft">
              Setting up a team starts from a studio registration. Studio Admin may invite clients, suppliers,
              colleagues or supervisors to join her/his studio.
            </p>
          </div>
          <img
            style={{ width: "50%", borderRadius: 16 }}
            src={require("../assets/sfScreenRmRegistApnEN.png")}
            alt="Setup team"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          Team members could be contract parties (e.g., consultants, lawyers and clients) or those within an
          organization (e.g., product developers, marketing analysts, sales and delivery engineers).
        </p>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Build Trust through Openness</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro interlinks Demand and Supply parties so that both teams can communicate and put things always on the
            table.
          </p>
        </div>

        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenInviteEN.png")}
          alt="Setup team"
        ></img>
        <p className="secDescriptionPnLeft">
          sf2Pro is committed to building a clean working space for teams to resolve those known pain points in the
          service industry, such as misunderstanding, obfuscation, unwritten rules and lack of trust.
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 2: Define Business Rules</h3>
          <p className="secDescriptionPnLeft">A service team may struggle with obfuscation and buck-passing.</p>
        </div>
        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 16 }}
          src={require("../assets/sfScreenConfigEN.png")}
          alt="Business rule"
        ></img>
        <p className="secDescriptionPnLeft">
          sf2Pro enables an evaluation mechanism to address this situation. The evaluator could be an internal or
          external expert, and the relevant evaluation type could be official or referential.
        </p>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Superior Teamwork under Game Rules</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro business templates cover 21 service divisions (refer to the division list during studio
            registration). And the relevant studio configs include:
          </p>
        </div>

        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenConfigDetailEN.png")}
          alt="Business rule"
        ></img>
        <p className="secDescriptionPnLeft">
          <br />
          Consensus items on collaboration
          <br />
          Criteria on deliverable evaluation
          <br />
          Rules on auto risk identification
          <br />
          Rules on performance calculation
          <br />
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div className="pnWrapperRowInside">
          <div style={{ width: "45%" }}>
            <h3 className="secTitlePnLeft">Scenario 3: Start Deliveries</h3>
            <p className="secDescriptionPnLeft">
              Requirements and solutions are expanded with various tasks, issues and risks.
            </p>
          </div>
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenReqCreateApnEN.png")}
            alt="Create requirement"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          Service practitioners ask for precise information classification and utilization. sf2Pro leverages multi-media
          data structure to help teams:
          <br />
          <br />
          - Avoid ambiguous object descriptions
          <br />
          - Improve data input efficiency
          <br />
        </p>
        <div className="pnWrapperRowInside" style={{ alignItems: "flex-start" }}>
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenObjCreateApnEN.png")}
            alt="Create business document"
          ></img>
          <div style={{ width: "45%" }}>
            <p className="secDescriptionPnLeft">
              Meanwhile, sf2Pro full version management is applied to help teams track object histories and minimize
              conflicts caused by unreliable memories.
            </p>
          </div>
        </div>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 4: Monitor Statuses</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro distributes information in detail and in real time. All stakeholders, e.g., task owners, issue
            responsible users, could be aligned through required status updates.
          </p>
        </div>
        <img
          style={{ width: "70%", marginTop: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenTaskEN.png")}
          alt="Task overview"
        ></img>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div className="pnWrapperRowInside">
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenReqDisplayApnEN.png")}
            alt="Display requirement"
          ></img>
          <div style={{ width: "50%" }}>
            <h3 className="secTitlePnRight">Business Successes Based on Associations</h3>
            <p className="secDescriptionPnLeft">
              One requirement could be associated with multiple solutions or vise versa. One solution could be realized
              by several tasks. A risk could be identified if it would impact any specific requirement or solution.
            </p>
          </div>
        </div>
        <p className="secDescriptionPnLeft">
          sf2Pro leverages a network to manage service objects and their associations. Auto alerts could be triggered
          and sent to stakeholders when the pre-defined conditions have been met.
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 5: Review and Analysis</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro reports object statuses in Kanbans. Historical statuses can be tracked thanks to the full version
            management applied.
          </p>
        </div>
        <img
          style={{ width: "90%", marginTop: 16, borderRadius: 16 }}
          src={require("../assets/sfScreenDoAEN.png")}
          alt="Objective DOA"
        ></img>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div className="pnWrapperRowInside">
          <div style={{ width: "40%" }}>
            <h3 className="secTitlePnLeft">Superb Songs Composed by Teams</h3>
            <p className="secDescriptionPnLeft">
              sf2Pro visualizes team performance and its fluctuation using music notes.
            </p>
          </div>
          <img
            style={{ width: "55%", borderRadius: 8 }}
            src={require("../assets/sfScreenIssueListEN.png")}
            alt="Display requirement"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          The higher a music note is, the better is the team DOA (Degree of Achievement). sf2Pro calculates DOAs based
          on rules which are defined by studio Admins.
          <br />
          <br />
          Each member might be evaluated by either an external expert (called Guest in sf2Pro) or an internal SME. The
          fair feedback from evaluators will be surely beneficial to team growth.
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 6: Studio Management</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro provides teams a safe and isolated digital space.
            <br />
            <br />A team member can join one or more studios. Studio Admin can invite new members, close their studios
            or transfer balances to other studios. Studio Admin and members they authorizes can post team notices or
            newsletters.
          </p>
        </div>
        <img
          style={{ width: "90%", marginTop: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenRoomListEN.png")}
          alt="Studio list"
        ></img>
      </div>

      <div className="pnWrapperRow">
        <img
          style={{ width: "42%", borderRadius: 16 }}
          src={require("../assets/sfScreenMyNetworkApnEN.png")}
          alt="My network"
        ></img>
        <div style={{ width: "53%" }}>
          <h3 className="secTitlePnRight" style={{ textAlign: "right" }}>
            Network is Your Fortune
          </h3>
          <p className="secDescriptionPnRight" style={{ textAlign: "right" }}>
            sf2Pro helps you maintain your network without omission. The network nodes include studio members who ever
            worked with you as your demand/supply parties, evaluators/evaluatees or risk/objective sharers.
          </p>
        </div>
      </div>

      <div className="pnWrapperRowRight">
        <p className="pcDirect" onClick={() => props.goResource()}>
          Register Now
        </p>
      </div>
    </div>
  );
}
